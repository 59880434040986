import React from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBPopover,
  MDBPopoverBody,
  MDBEdgeHeader
} from 'mdbreact';

import './Team.css';

import volkmar from '../assets/team/volkmar.jpg';
import stefanie from '../assets/team/stefanie.jpg';
import cristina from '../assets/team/cristina.jpg';
import mayte from '../assets/team/mayte.jpg';
import pablo from '../assets/team/pablo.jpg';
import magda from '../assets/team/magda.jpg';
import paola from '../assets/team/paola.jpeg';
import christiane from '../assets/team/christiane.jpg';
import jordi from '../assets/team/jordi.jpeg'
import franziska from '../assets/team/franziska.jpeg'
import { withTranslation } from 'react-i18next';

const { MDBAnimation } = require('mdbreact')

const Team = (props: { t: (arg0: string) => {} | null | undefined; }) => {
  return(
    <>
    <meta name="robots" content="follow" />
      <div className='mainSectionTeam'>
      <MDBEdgeHeader color='indigo darken-2' className='sectionPage_schwerpunkte' />
        <MDBAnimation type='zoomIn' duration='500ms'>
          <MDBContainer>
                <MDBRow>
                  <MDBCol md='12' className='mt-4'>
                    <MDBRow id='categories'>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInLeft'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="volkmarImg" className="cardPictureMain" src={volkmar}></img>
                            <MDBCardBody cascade className='cardBody text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Volkmar Wiczinowski</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.volkmar_desc')}</span>
                              </MDBCardText>
                                <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                  <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                  {props.t('team.volkmar_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInDown'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="cristinaImg" className="cardPictureMain" src={cristina}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Cristina Garcia</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.cristina_desc')}</span>
                              </MDBCardText>  
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                    {props.t('team.cristina_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="stefanieImg" className="cardPictureMain" src={stefanie}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Stefanie Wiczinowski</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.stefanie_desc')}</span>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                    {props.t('team.stefanie_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow id='categories'>
                    <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="mayteImg" className="cardPictureMain" src={mayte}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Mayte Gónzalez</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.mayte_desc')}</span>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                <MDBPopoverBody className='popover-teams-description'>
                                  {props.t('team.mayte_pop')}
                                </MDBPopoverBody>
                              </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="pabloImg" className="cardPictureMain" src={pablo}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Juan Pablo Uribe</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.pablo_desc')}</span>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                    {props.t('team.pablo_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="jordiImg" className="cardPictureMain" src={jordi}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Jordi Jorro</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.jordi_desc')}</span>
                                <br/><br/>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                    {props.t('team.jordi_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow id='categories'>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="christianeImg" className="cardPictureMain" src={christiane}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Christiane Wein</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.christiane_desc')}</span>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                    {props.t('team.christiane_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="magdaImg" className="cardPictureMain" src={magda}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Magda Ebert</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.magda_desc')}</span>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                  {props.t('team.magda_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="paolaImg" className="cardPictureMain" src={paola}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Paola Martinez Jimenez</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.paola_desc')}</span>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                    {props.t('team.paola_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow id='categories'>
                      <MDBCol md='4'>
                        <MDBAnimation reveal type='fadeInRight'>
                          <MDBCard cascade className='my-3 grey lighten-4'>
                            <img alt="franziskaImg" className="cardPictureMain" src={franziska}></img>
                            <MDBCardBody cascade className='text-center'>
                              <MDBCardTitle className="cardTitle">
                                <span>Franziska Pfeiffer</span>
                              </MDBCardTitle>
                              <MDBCardText className="cardDescription">
                                <span>{props.t('team.franziska_desc')}</span>
                              </MDBCardText>
                              <MDBPopover placement='top' popover clickable className="popover-teams-content">
                                <MDBBtn color="light-blue" size="sm">+ Info</MDBBtn>
                                  <MDBPopoverBody className='popover-teams-description'>
                                    {props.t('team.franziska_pop')}
                                  </MDBPopoverBody>
                                </MDBPopover>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBAnimation>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
          </MDBContainer>
        </MDBAnimation>
      </div>
    </>
  );
};

export default withTranslation('common')(Team);
