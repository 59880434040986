import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import App from "./app/App";
import registerServiceWorker from './api/_helpers/registerServiceWorker';
import i18next  from "i18next";
import {I18nextProvider} from "react-i18next";
import deTranslations from "./app/assets/translations/de.json";
import esTranslations from "./app/assets/translations/es.json";
import enTranslations from "./app/assets/translations/en.json";

if (!localStorage.getItem('languageSelected')) {
    localStorage.setItem('languageSelected', 'de')
}

i18next.init({
    interpolation: { escapeValue: false },
    lng: `${localStorage.getItem('languageSelected')}`,
    fallbackLng: "de",
    resources: {
        de: {
            common: deTranslations
        },
        es: {
            common: esTranslations
        },
        en: {
            common: enTranslations
        }
    }
});

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>,
    document.getElementById('root')
);

registerServiceWorker();