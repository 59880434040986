import { UserService } from "../_services/users/UserService";

export function handleResponse(response: any) {
    const userService = new UserService()
    
    return response.text()
        .then((res: any) => {
            if (!response.ok) {
                let error = ''
                if ([401, 403, 404].indexOf(response.status) !== -1) {
                    userService.logout();
                    error = 'Server unavailable at the moment, please try again later'
                } else {
                    error = 'Please try again later, if the error persists, contact us!'
                }
                return Promise.reject(error);
            } else if (res === "unauthorized") {
                let error = 'Wrong username or password, please contact us if the error persists'
                return Promise.reject(error)
            }
        return JSON.parse(res);
        })
}