import React from 'react';
import {
  MDBRow,
  MDBCol,
  MDBFreeBird,
  MDBCardBody,
  MDBCardImage,
  MDBEdgeHeader,
  MDBAnimation
} from 'mdbreact';
import './Philosophie.css';
import { withTranslation } from 'react-i18next';
import equipo from '../assets/team/equipo.jpg'

const AdvancedNavPage = (props: { t: (arg0: string) => {} | null | undefined; }) => {
  return (
    <>
      <div className='mainSectionPhilosophie'>
        <MDBEdgeHeader className='sectionPage_philosophie' />
        <MDBAnimation type='fadeIn'>
          <MDBFreeBird>
            <MDBRow>
              <MDBCol md='10' className='mx-auto float-none white z-depth-1 py-2 px-2'>
                <MDBCardBody className='text-justify'>
                  <MDBCardImage cascade className='mainImage' src={equipo}/>
                      <div className="philosophyText">
                        <p>{props.t('philosophy.p1')}</p>
                        <p>{props.t('philosophy.p2')}</p>
                        <p>{props.t('philosophy.p3')}</p>
                        <p>{props.t('philosophy.p4')}</p>
                        <p>{props.t('philosophy.p5')}</p>
                        <p>{props.t('philosophy.p6')}</p>
                        <p>{props.t('philosophy.p7')}</p>
                        <p>{props.t('philosophy.p8')}</p>
                        <p>{props.t('philosophy.p9')}</p>
                      </div>
                  <MDBRow />
                </MDBCardBody>
                <p className="text-center"><h2>{props.t('philosophy.p10')}</h2></p>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
          </MDBAnimation>
        </div>
    </>
  );
};

export default withTranslation('common')(AdvancedNavPage);
