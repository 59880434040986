import React from 'react';
import { MDBCol, MDBEdgeHeader, MDBAnimation } from 'mdbreact';
import { Collapse, Button, CardBody, Card, } from 'reactstrap';
import './Schwerpunkte.css';
import { withTranslation } from 'react-i18next';
import advice from '../assets/schwerpunkte/advice.jpg'
import company from '../assets/schwerpunkte/companies.png'
import persons from '../assets/schwerpunkte/persons.jpg'
import real_state from '../assets/schwerpunkte/real_state.jpg'
import pension from '../assets/schwerpunkte/pension.jpg'

interface MyProps {
  t: any;
}

interface MyState {
  AllgemeineButton: number | null,
  UnternehmenButton: number | null,
  PrivatpersonenButton: number | null,
  InmobilieButton: number | null,
  VorsorgeButton: number | null
}

class Buttons extends React.Component<MyProps, MyState> {
  constructor(props: MyProps){
    super(props);
    this.state = {
      AllgemeineButton: null,
      UnternehmenButton: null,
      PrivatpersonenButton: null,
      InmobilieButton: null,
      VorsorgeButton: null
    }

    this.setAllgemeineBeratung = this.setAllgemeineBeratung.bind(this);
    this.setUnternehmen = this.setUnternehmen.bind(this);
    this.setPrivatpersonen = this.setPrivatpersonen.bind(this);
    this.setInmobilie = this.setInmobilie.bind(this);
    this.setVorsorge = this.setVorsorge.bind(this);
  }

  setAllgemeineBeratung (id: number) {
    if (this.state.AllgemeineButton === 0 && id === 0) {
      this.setState({ AllgemeineButton: null });
    } else {
      this.setState({ AllgemeineButton: id });
      this.setState({ UnternehmenButton: null, PrivatpersonenButton: null, InmobilieButton: null, VorsorgeButton: null });
    }
  };

  setUnternehmen (id: number) {
    if (this.state.UnternehmenButton === 0 && id === 0) {
      this.setState({ UnternehmenButton: null });
    } else {
      this.setState({ UnternehmenButton: id });
      this.setState({ AllgemeineButton: null, PrivatpersonenButton: null, InmobilieButton: null, VorsorgeButton: null });
    }
  };

  setPrivatpersonen (id: number) {
    if (this.state.PrivatpersonenButton === 0 && id === 0) {
      this.setState({ PrivatpersonenButton: null });
    } else {
      this.setState({ PrivatpersonenButton: id });
      this.setState({ AllgemeineButton: null, UnternehmenButton: null, InmobilieButton: null, VorsorgeButton: null });
    }
  };

  setInmobilie (id: number) {
    if (this.state.InmobilieButton === 0 && id === 0) {
      this.setState({ InmobilieButton: null });
    } else {
      this.setState({ InmobilieButton: id });
      this.setState({ AllgemeineButton: null, PrivatpersonenButton: null, UnternehmenButton: null, VorsorgeButton: null });
    }

  };

  setVorsorge (id: number) {
    if (this.state.VorsorgeButton === 0 && id === 0) {
      this.setState({ VorsorgeButton: null });
    } else {
      this.setState({ VorsorgeButton: id });
      this.setState({ AllgemeineButton: null, PrivatpersonenButton: null, UnternehmenButton: null, InmobilieButton: null });
    }
  };


  render() {
    const { t } = this.props
    const { AllgemeineButton } = this.state;
    const { UnternehmenButton } = this.state;
    const { PrivatpersonenButton } = this.state;
    const { InmobilieButton } = this.state;
    const { VorsorgeButton } = this.state;

    return(
    <div className="mainSectionSchwerpunkte">
      <MDBEdgeHeader color='indigo darken-2' className='sectionPage_schwerpunkte' />
        <MDBAnimation type='fadeInLeft'>
          <MDBCol md='7' className= 'mx-auto float-none py-1 px-1'>
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col">
                <div className="cards card">
                <img height="250" src={advice} className="card-img-top" alt="advice"/>
                  <div className="card-body">
                    <h5 className="card-title">
                      <Button color="blue" onClick={ () => this.setAllgemeineBeratung(0) }
                      style={{ width: '300px' }}>{t('activity.button1')}</Button>
                    </h5>
                    <Collapse isOpen={ AllgemeineButton === 0 }>
                      <Card>
                        <CardBody className="card1 alignSecond">
                          <li>{t('activity.b1_a')}</li>
                          <li>{t('activity.b1_b')}</li>
                          <li>{t('activity.b1_c')}</li>
                          <li>{t('activity.b1_d')}</li>
                          <li>{t('activity.b1_e')}</li>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
              </div>
            </div>

            <div className="col">
              <div className="cards card">
                <img src={company} height="250" className="card-img-top" alt="..."/>
                <div className="card-body">
                    <h5 className="card-title">
                      <Button color="blue" onClick={ () => this.setUnternehmen(0) }
                      style={{ width: '300px' }}>{t('activity.button2')}</Button>
                    </h5>
                    <Collapse isOpen={ UnternehmenButton === 0 }>
                      <Card>
                        <CardBody className="card2">
                            <Button color="light" onClick={ () => this.setUnternehmen(1) } style={{ marginBottom: '1rem', width: '215px' }}>{t('activity.b2a_title')}</Button>
                            <Button color="light" onClick={ () => this.setUnternehmen(2) } style={{ marginBottom: '1rem', width: '215px' }}>{t('activity.b2b_title')}</Button>
                        </CardBody>
                      </Card>
                    </Collapse>
                    <Collapse isOpen={ UnternehmenButton === 1 }>
                  <Card className="card2">
                      <CardBody className="card2 alignSecond">
                        <h4> {t('activity.b2a_title')} </h4>
                        <li>{t('activity.b2a_a')}</li>
                        <li>{t('activity.b2a_b')}</li>
                        <li>{t('activity.b2a_c')}</li>
                        <li>{t('activity.b2a_d')}</li>
                        <li>{t('activity.b2a_e')}</li>
                        <li>{t('activity.b2a_f')}</li>
                        <li>{t('activity.b2a_g')}</li>
                        <li>{t('activity.b2a_h')}</li>
                        <li>{t('activity.b2a_i')}</li>
                        <li>{t('activity.b2a_j')}</li>
                        <li>{t('activity.b2a_k')}</li>
                        <li>{t('activity.b2a_l')}</li>
                      </CardBody>
                    </Card>
                  </Collapse>
                    <Collapse isOpen={ UnternehmenButton === 2 }>
                    <Card className="card2">
                      <CardBody className="card2 alignSecond">
                        <h4> {t('activity.b2b_title')} </h4>
                          <li>{t('activity.b2b_a')}</li>
                          <li>{t('activity.b2b_b')}</li>
                          <li>{t('activity.b2b_c')}</li>
                          <li>{t('activity.b2b_d')}</li>
                          <li>{t('activity.b2b_e')}</li>
                          <li>{t('activity.b2b_f')}</li>
                          <li>{t('activity.b2b_g')}</li>
                          <li>{t('activity.b2b_h')}</li>
                          <li>{t('activity.b2b_i')}</li>
                          <li>{t('activity.b2b_j')}</li>
                          <li>{t('activity.b2b_k')}</li>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="cards card">
                <img src={persons} height="250" className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">
                      <Button color="blue" onClick={ () => this.setPrivatpersonen(0) }
                      style={{ width: '300px' }}>{t('activity.button3')}</Button>
                    </h5>
                    <Collapse isOpen={ PrivatpersonenButton === 0 }>
                    <Card>
                      <CardBody className="card3">
                          <Button color="light" onClick={ () => this.setPrivatpersonen(1) } style={{ marginBottom: '1rem', width: '215px' }}>{t('activity.b3a_title')}</Button>
                          <Button color="light" onClick={ () => this.setPrivatpersonen(2) } style={{ marginBottom: '1rem', width: '215px' }}>{t('activity.b3b_title')}</Button>
                      </CardBody>
                    </Card>
                  </Collapse>
                    <Collapse isOpen={ PrivatpersonenButton === 1 }>
                    <Card>
                      <CardBody className="card3 alignSecond">
                        <h4> {t('activity.b3a_title')} </h4>
                          <li>{t('activity.b3a_a')}</li>
                          <li>{t('activity.b3a_b')}</li>
                          <li>{t('activity.b3a_c')}</li>
                          <li>{t('activity.b3a_d')}</li>
                          <li>{t('activity.b3a_e')}</li>
                          <li>{t('activity.b3a_f')}</li>
                      </CardBody>
                    </Card>
                  </Collapse>
                    <Collapse isOpen={ PrivatpersonenButton === 2 }>
                    <Card>
                      <CardBody className="card3 alignSecond">
                        <h4> {t('activity.b3b_title')} </h4>
                          <li>{t('activity.b3b_a')}</li>
                          <li>{t('activity.b3b_b')}</li>
                          <li>{t('activity.b3b_c')}</li>
                          <li>{t('activity.b3b_d')}</li>
                          <li>{t('activity.b3b_e')}</li>
                          <li>{t('activity.b3b_f')}</li>
                          <li>{t('activity.b3b_g')}</li>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="cards card">
                <img
                  src={real_state}
                  className="card-img-top"
                  alt="..."
                  height="250"
                />
                <div className="card-body">
                    <h5 className="card-title">
                      <Button color="blue" onClick={ () => this.setInmobilie(0) }
                      style={{ width: '300px' }}>{t('activity.button4')}</Button>
                    </h5>
                    <Collapse isOpen={ InmobilieButton === 0 }>
                    <Card>
                      <CardBody className="card4 text-center">
                          <Button color="light" onClick={ () => this.setInmobilie(1) } style={{ marginBottom: '1rem', width: '215px' }}>{t('activity.b4a_title')}</Button>
                          <Button color="light" onClick={ () => this.setInmobilie(2) } style={{ marginBottom: '1rem', width: '215px' }}>{t('activity.b4b_title')}</Button>
                          <Button color="light" onClick={ () => this.setInmobilie(3) } style={{ marginBottom: '1rem', width: '265px' }}>{t('activity.b4c_title')}</Button>
                      </CardBody>
                    </Card>
                  </Collapse>
                    <Collapse isOpen={ InmobilieButton === 1 }>
                      <Card>
                        <CardBody className="card4 alignSecond">
                          <h4> {t('activity.b4a_title')} </h4>
                            <li>{t('activity.b4a_a')}</li>
                            <li>{t('activity.b4a_b')}</li>
                            <li>{t('activity.b4a_c')}</li>
                            <li>{t('activity.b4a_d')}</li>
                            <li>{t('activity.b4a_e')}</li> 
                        </CardBody>
                      </Card>
                    </Collapse>
                    <Collapse isOpen={ InmobilieButton === 2 }>
                      <Card>
                        <CardBody className="card4 alignSecond">
                          <h4> {t('activity.b4b_title')} </h4>
                            <li>{t('activity.b4b_a')}</li>
                            <li>{t('activity.b4b_b')}</li>
                            <li>{t('activity.b4b_c')}</li>
                            <li>{t('activity.b4b_d')}</li>
                            <li>{t('activity.b4b_e')}</li> 
                        </CardBody>
                      </Card>
                    </Collapse>
                    <Collapse isOpen={ InmobilieButton === 3 }>
                    <Card>
                      <CardBody className="card4 alignSecond">
                        <h4> {t('activity.b4c_title')} </h4>
                            <li>{t('activity.b4c_a')}</li>
                            <li>{t('activity.b4c_b')}</li>
                            <li>{t('activity.b4c_c')}</li>
                            <li>{t('activity.b4c_d')}</li>
                            <li>{t('activity.b4c_e')}</li> 
                            <li>{t('activity.b4c_f')}</li> 
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            </div>
            <div className="col">
              <div className="cards_one card">
                <img src={pension} className="card-img-top" alt="..." height="250" width="400"/>
                <div className="card-body">
                    <h5 className="card-title">
                      <Button color="blue" onClick={ () => this.setVorsorge(0) }
                      style={{ width: '300px' }}>{t('activity.button5')}</Button>
                    </h5>
                    <Collapse isOpen={ VorsorgeButton === 0 }>
                    <Card>
                      <CardBody className="card5 text-center">
                          <Button color="light" onClick={ () => this.setVorsorge(1) } style={{ marginBottom: '1rem', width: '330px' }}>{t('activity.b5a_title')}</Button>
                          <Button color="light" onClick={ () => this.setVorsorge(2) } style={{ marginBottom: '1rem', width: '330px' }}>{t('activity.b5b_title')}</Button>
                          <Button color="light" onClick={ () => this.setVorsorge(3) } style={{ marginBottom: '1rem', width: '330px' }}>{t('activity.b5c_title')}</Button>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <Collapse isOpen={ VorsorgeButton === 1 }>
                    <Card>
                      <CardBody className="card5 alignSecond">
                        <h4> {t('activity.b5a_title')} </h4>
                            <li>{t('activity.b5a_a')}</li>
                            <li>{t('activity.b5a_b')}</li>
                            <li>{t('activity.b5a_c')}</li>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <Collapse isOpen={ VorsorgeButton === 2 }>
                    <Card>
                      <CardBody className="card5 alignSecond">
                        <h4> {t('activity.b5b_title')} </h4>
                            <li>{t('activity.b5b_a')}</li>
                            <li>{t('activity.b5b_b')}</li>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <Collapse isOpen={ VorsorgeButton === 3 }>
                    <Card>
                      <CardBody className="card5 alignSecond">
                      <h4> {t('activity.b5c_title')} </h4>
                            <li>{t('activity.b5c_a')}</li>
                            <li>{t('activity.b5c_b')}</li>
                            <li>{t('activity.b5c_c')}</li>
                            <li>{t('activity.b5c_d')}</li>
                            <li>{t('activity.b5c_e')}</li>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </div>
            </div>
            </MDBCol>
      </MDBAnimation>
    </div>
    )
  }
}

export default withTranslation('common')(Buttons);
