import Helmet from 'react-helmet';

const getDefault = {
    title: "Euro Fiscal", 
    description: "Asesoría Fiscal",
    keywords: "asesoria, fiscal, impuestos, testamentos, inmobiliaria, compraventa, alemán, laboral",
    robots: "",
    canonicalUrl: "https://www.eurofiscal.net"
};

export const getPageMetadata = (props: any) => {
    const { title, description, keywords, robots, canonicalUrl } = props;
    const defaultData = getDefault
    return {
        title: title || defaultData?.title,
        description: description || defaultData?.description,
        keywords: keywords || defaultData?.keywords,
        robots: canonicalUrl || defaultData?.canonicalUrl,
        canonicalUrl: canonicalUrl || defaultData?.canonicalUrl,
    };
};

const MetaTags = (props: any) => {
    const metaData = getDefault;
    const meta = getPageMetadata(metaData);
    return (
        <Helmet>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            <meta name="keywords" content={meta.keywords} />
            {props.robots && <meta name="robots" content={meta.robots} />}
            <link rel="canonical" href={meta.canonicalUrl} />
        </Helmet>
    );
};

export default MetaTags;