import './Kanzlei.css';
import { withTranslation } from 'react-i18next';
import 'react-slideshow-image/dist/styles.css'
import { MDBEdgeHeader } from 'mdbreact';

const { Fade } = require('react-slideshow-image');

const properties = {
  duration: 16000
}

const Kanzlei = (props: { t: (arg0: string) => {} | null | undefined; }) => {
  return (
    <>
    <MDBEdgeHeader className='sectionPage_kanzlei' />
    <div className="slide-container">
        <Fade {...properties}>
          <div className="each-slide">
            <div>
              <p>{props.t('company.p1')}</p>
            </div>
          </div>
          <div className="each-slide">
            <div>
              <p>{props.t('company.p2')}</p>
            </div>
          </div>
          <div className="each-slide">
            <div>
              <p>{props.t('company.p3')}</p>
            </div>
          </div>
          <div className="each-slide">
            <div>
              <p>{props.t('company.p4')}</p>
            </div>
          </div>
          <div className="each-slide">
            <div>
              <p>{props.t('company.p5')}</p>
            </div>
          </div>
          <div className="each-slide">
            <div>
              <p>{props.t('company.p6')}</p>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
};

export default withTranslation('common')(Kanzlei);
