import React from 'react';
import { Formik, ErrorMessage, Form, Field } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import './LoginForm.css';
import { spinner } from '../../assets/login/spinner';
import { UserService } from '../../_services/users/UserService';

interface MyProps {
    t: any
}

class LoginForm extends React.Component<MyProps> {
    userService = new UserService()
    
    constructor(props: MyProps) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return (
                <Formik
                    initialValues = {{ email: '', password: '' }}
                    validationSchema = {
                        Yup.object().shape({
                            email: Yup.string().required(t('user.username_required')),
                            password: Yup.string().required(t('user.password_required'))
                        })
                    }
                    onSubmit = {({ email, password }, { setStatus, setSubmitting }) => {
                        setStatus();
                        this.userService.login(email, password)
                            .then(
                                () => console.log("Succesfully logged in"),
                                error => {
                                    setSubmitting(false);
                                    setStatus(error);
                                }
                            ).catch(
                                () => {
                                    setSubmitting(false);
                                    setStatus("Could not login, please try again later");
                                }
                            )
                    }}
                    >
                    {({ errors, status, touched, isSubmitting }) => (
                        <Form>
                            <div className="login-form">
                                <label htmlFor="email">Email</label>
                                <Field
                                    name="email" 
                                    type="text" 
                                    className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name="username" component="div" className="invalid-feedback" />

                                <label htmlFor="password">{t('user.password')}</label>
                                <Field 
                                    name="password" 
                                    type="password" 
                                    className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                                />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                
                                <button
                                    type="submit" 
                                    className="btn btn-primary" 
                                    disabled={isSubmitting}>
                                        {t('user.login')}
                                </button>
                                { isSubmitting && <img alt ='submitting' className="submitting" src={spinner} /> }
                            </div>
                            {status && <div className={'alert alert-danger'}>{status}</div>}
                        </Form>
                    )}
                </Formik>
        )
    }
}

export default withTranslation('common')(LoginForm);