import React from 'react';
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBCol,
  MDBIcon,
  MDBFreeBird
} from 'mdbreact';
import './Kontakt.css';
import { withTranslation } from 'react-i18next';

const { MDBAnimation } = require('mdbreact')

const FormsNavPage = (props: { t: (arg0: string) => {} | null | undefined; }) => {
  return (
    <>
      <div className="mainSectionLocation">
        <MDBEdgeHeader className='sectionPage_kontakt' />
          <MDBAnimation type='zoomIn' duration='500ms'>
            <MDBFreeBird>
              <MDBContainer className='justify-content-center'>
                  <MDBCol md='7' className='mx-auto float-none white z-depth-1 py-2 px-2'>
                          <h3 className="strongHeader"><b>{props.t('location.where')}</b></h3>
                          <h4 className="descriptionTimes"><MDBIcon className="iconTimes" icon="map-marker-alt"/> Calle Marqués de Campo</h4>
                          <h4 className="descriptionTimes"><MDBIcon className="iconTimes" icon="list-ol"/> 56º - 2 {props.t('location.and')} 3 {props.t('location.floor')}</h4>
                          <h4 className="descriptionTimes"><MDBIcon className="iconTimes" icon="city"/> Denia (Alicante) - 03700</h4>
                      <div id="map-container-google-1" className="map z-depth-1-half map-container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12430.509316832538!2d0.1108832!3d38.8409949!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2c07ec17c1cd0639!2sEuro%20Fiscal%20Levante%20S.L.!5e0!3m2!1sen!2ses!4v1601729141627!5m2!1sen!2ses" width="600" height="450" frameBorder="0" title="eurofiscal" aria-hidden="false"></iframe>
                      </div>
                    </MDBCol>
              </MDBContainer>
            </MDBFreeBird>
          </MDBAnimation>
      </div>
    </>
  );
};

export default withTranslation('common')(FormsNavPage);
