import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Kanzlei from './pages/Kanzlei';
import Team from './pages/Team';
import Schwerpunkte from './pages/Schwerpunkte';
import Philosophie from './pages/Philosophie';
import Kontakt from './pages/Kontakt';
import Standort from './pages/Standort';
import Impressum from './pages/Impressum';
import Admin from './userpanel/pages/admin/Admin';
import Uploads from './userpanel/pages/uploads/Uploads';
import Dashboard from './userpanel/pages/dashboard/Dashboard';
import UserProfile from './userpanel/pages/userProfile/UserProfile';
import { UserService } from './_services/users/UserService';

class Routes extends React.Component {
  userService = new UserService()
  
  render() {
    return (
      <Switch>
        {this.userService.getCurrentUser().email
          ?<Route exact path='/' component={Dashboard}/>
          :<Route exact path='/' component={HomePage}/>
        }
        
        <Route exact path='/home' component={HomePage} />
        <Route exact path='/philosophie' component={Philosophie} />
        <Route exact path='/schwerpunkte' component={Schwerpunkte} />
        <Route exact path='/kanzlei' component={Kanzlei} />
        <Route exact path='/team' component={Team} />
        <Route exact path='/kontakt' component={Kontakt} />
        <Route exact path='/standort' component={Standort} />
        <Route exact path='/impressum' component={Impressum} />

        <Route exact path='/user/admin' component={Admin} />
        <Route exact path='/user/profile' component={UserProfile} />
        <Route exact path='/user/uploads' component={Uploads} />
        <Route exact path='/user/dashboard' component={Dashboard} />
      </Switch>
    );
  }
}

export default Routes;
