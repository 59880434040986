import { MDBFooter } from "mdbreact"
import './WebFooter.css'

export const WebFooter = () => {
    return  <MDBFooter color='footer indigo'>
                <nav className="navbar navbar-dark justify-content-center">
                    {new Date().getFullYear()} Euro Fiscal Levante S.L. &nbsp;&nbsp;&nbsp;
                    <a href="/#impressum"> Impressum</a>
                </nav>
            </MDBFooter>
}