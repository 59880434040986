import React from 'react'
import _, { Dictionary } from 'lodash';

import './ResponsiveTable.css';
import { MDBCol, MDBIcon } from 'mdbreact';
  
interface TableProps {
  columns: Dictionary<any>,
  rows: Array<any>,
  admin: Boolean,
  lang: Function
}

interface TableState {
  tableRows: Array<any>
}

class ResponsiveTable extends React.Component<TableProps, TableState> {
    constructor(props: TableProps) {
      super(props);
      this.state = {
        tableRows: this.props.rows
      };
    }
  

    searchForCompany(e: any): void {
      for (let i=0; i<this.props.rows.length; i++){
        let searchValue = e.value.trim().toLowerCase()
        this.setState(
          {
            tableRows: this.props.rows.filter(row => row.company.toLowerCase().includes(searchValue))
          }
        )
      }
    }

    searchForFile(e: any): void {
      for (let i=0; i<this.props.rows.length; i++) {
        let searchValue = e.value.trim().toLowerCase()
        this.setState(
          {
            tableRows: this.props.rows.filter(row => row.file_name.toLowerCase().includes(searchValue))
          }
        )
      }
    }

    searchForYear(e: any): void {
      for (let i=0; i<this.props.rows.length; i++) {
        let searchValue = e.value.trim().toLowerCase()
        this.setState(
          {
            tableRows: this.props.rows.filter(row => row.date.toLowerCase().includes(searchValue))
          }
        )
      }
    }

    _head = () => {
      var columns = _.map(this.props.columns, function(colName: string) {
        return (
          <th key={Math.random()}><strong>{colName}</strong></th>
        );
      });
      return (
        <tr className="uploadTableHeader" key={Math.random()}>{columns}</tr>
      );
    };
    
    _rows = () => {
      var _this = this;
      return _.map(this.state.tableRows, function(row: string) {
        var values = _.map(_this.props.columns, function(colName: string, colKey: number) {
          return (
            <td className="uploadTableRows" key={Math.random()} id={colName}>{row[colKey]}</td>
          );
        })
        return (
          <tr key={Math.random()}>{values}</tr>
        );
      })
    };

    render = () => {
      return (
        <>
          <MDBCol md="12">
          <div className="row row-cols-1 row-cols-md-4">
            <div className="col">
              <form className="form-inline mt-4 mb-4">
                <MDBIcon icon="search" />
                {this.props.admin?
                <input
                  onKeyUp={e => this.searchForCompany(e.target)}
                  className="form-control form-control-sm ml-3 w-75" type="text"
                  placeholder={this.props.lang('user.search_for_company')} aria-label="Search"
                />
                :
                <input
                  onKeyUp={e => this.searchForFile(e.target)}
                  className="form-control form-control-sm ml-3 w-75" type="text"
                  placeholder={this.props.lang('user.search_for_file')} aria-label="Search"
                />}
              </form>
              </div>
              <div className="col">
                <form className="form-inline mt-4 mb-4">
                    <MDBIcon icon="calendar" />
                    <input
                      onKeyUp={e => this.searchForYear(e.target)}
                      className="form-control form-control-sm ml-3 w-75" type="text"
                      placeholder={this.props.lang('user.search_for_year')} aria-label="Search"
                    />
                  </form>
              </div>
            </div>
          </MDBCol>
          <table className="fl-table">
            <thead>
              {this._head()}
            </thead>
            <tbody>
              {this._rows()}
            </tbody>
          </table>
        </>
      );
    };
}

export default ResponsiveTable;