import React from 'react';
import { withTranslation } from 'react-i18next';
import { MDBEdgeHeader, MDBFreeBird, MDBCol, MDBCardBody,
         MDBContainer, MDBTabContent, MDBTabPane, MDBView
} from 'mdbreact';
import './Dashboard.css';
import Activities from '../../components/UserActivities'
import { UserState } from '../../models/Userstate';
import { UserProps } from '../../models/UserProps'
import DataModal from '../../components/DataModal';
import { UserService } from '../../../_services/users/UserService';

const { MDBAnimation } = require('mdbreact')

class Dashboard extends React.Component<UserProps, UserState> {
    userService = new UserService()
    overlay: any;
    constructor(props: UserProps) {
        super(props);

        this.overlay = React.createRef()

        this.state = {
            currentUser: this.userService.getCurrentUser(),
            selectedFile: null,
            activeItem: "1",
            passwordTooShort: false,
            contactSupport: false,
            submittedFile: false,
            loaded: null
        };
    }

    render() {
      const { t } = this.props;
        return (
          <>
            <meta name="robots" content="follow" />
            <div className='dashboard-background'/>
            <MDBAnimation type='fadeIn'>
            <DataModal/>
            <div className="dashboard-container">
                  <MDBCol className='float-none white z-depth-1 ' >
                    <MDBCardBody className='text-justify'>
                      <div className='uploadBox'>
                        <p>{t('user.textpanel_2')}</p>
                          <MDBAnimation type='fadeIn' duration='500ms'>
                              <div className="view overlay zoom">
                                <MDBView zoom>
                                  <Activities lang={t} admin={false}/>
                                </MDBView>
                              </div>
                          </MDBAnimation>
                      </div>
                    </MDBCardBody>
                  </MDBCol>
              </div>
            </MDBAnimation>
        </>
        );
    }
}

export default withTranslation('common')(Dashboard);
