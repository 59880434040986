import { useState, useEffect } from 'react';
import { API_URL }  from '../../../environment/environment'
import reports from '../../assets/userpanel/reports.png'
import Charts from './Charts'
import './ResponsiveTable.css'
import ResponsiveTable from './ResponsiveTable'
import $ from 'jquery';
import { translationHandler  } from '../facilities/translationHandler';
import { UserService } from '../../_services/users/UserService';

const Activities = (props: { lang: any, admin: boolean }) => {
    const userService = new UserService()
    const [activity, setActivity] = useState<any[]>([]);
    useEffect(() => {
      if (activity.length == 0) {
        userActivity()
      }
    }, []);
  
    var userActivities: object[] = [];
    let url = props.admin
                ? `${API_URL}/users/activity/all`
                : `${API_URL}/users/activity`;

    const userActivity = async () => {
      const settings = {
        "url": url,
        headers: { 
          Authorization: `Bearer ${userService.getCurrentUser().token}`
        },
        "method": "GET",
        "timeout": 0
      };
      
      let response = await $.ajax(settings)
      let activities = JSON.parse(response)

      for (let i=0; i < activities.length; i++) {
        activities[i].date = `${activities[i].date.substring(0, 10)}`
        translationHandler(activities[i], props.lang);
        userActivities.push(activities[i])
      }

      setActivity(userActivities)
    }
    
    var cols = {
      company: props.lang('user.company'),
      date: props.lang('user.date'), 
      file_name: props.lang('user.file_names'), 
      department: props.lang('user.department'), 
      category: props.lang('user.category')
    }

    if (activity.length > 0) {
      return <>
                <Charts 
                  lang={props.lang} 
                  totalFiles={activity.length} 
                  lastUpdate={activity[0].date.substring(0, 10)}
                />
                <ResponsiveTable 
                  columns={cols} 
                  rows={activity} 
                  admin={props.admin} 
                  lang={props.lang}
                />
             </>
    }
    
    return <div className="emptyUploadsImg">
              <img alt="no-upload" width="880px" src={reports}/>
            </div>
    
  }

export default Activities;