import React from 'react';
import { withTranslation } from 'react-i18next';
import logo from '../../assets/eurofiscal_logo.png'
import './UserNavbar.css';
import { MDBBtn } from 'mdbreact';
import NotificationService from '../../_services/notifications/NotificationService';
import { NotificationTypes } from '../../_services/notifications/models/NotificationTypes';
import { UserPagesHashes } from './models/UserPagesHashes';
import { UserService } from '../../_services/users/UserService';

const notificationService = new NotificationService();

interface UserNavbarProps {
    t: any
}

interface UserNavbarState {
    activeTabId: number
}

class UserNavbar extends React.Component<UserNavbarProps, UserNavbarState> {
    userService = new UserService()

    constructor(props: UserNavbarProps) {
        super(props);

        this.logoutUser = this.logoutUser.bind(this);
        this.setActiveTabId = this.setActiveTabId.bind(this);

        this.state = {
            activeTabId: this.getActiveTabId()
        }
    }

    getActiveTabId() {
        switch (window.location.hash) {
            case UserPagesHashes.CREATE_USERS:
                return 4;
            case UserPagesHashes.UPLOADS:
                return 3;
            case UserPagesHashes.MY_PROFILE:
                return 2;
            default:
                return 1;
        }
    }

    logoutUser() {
        this.userService.logout();
        notificationService.display(NotificationTypes.SUCCESS, this.props.t('user.loggedout'))
    }

    setActiveTabId(tabId: number) {
        this.setState({activeTabId: tabId})
    }

    render() {
        let activeTabId = this.state.activeTabId;

        if (this.userService.getCurrentUser().email) 
            notificationService.display(NotificationTypes.SUCCESS, this.props.t('user.loggedin'))

        if (this.userService.getCurrentUser().dataAcceptance === 0) 
            return '';

        return <div className="user-navbar-outer" color='indigo'>
                    <img
                        src={logo}
                        className="profile-img img-fluid rounded-circle"
                        alt="Profile image"
                    />

                    <div className="profile-name">{this.userService.getCurrentUser().name}</div>
                    
                    <MDBBtn onClick={() => this.logoutUser()} className='button-logout btn btn-dark'><span><i className="fas fa-sign-out-alt"></i> {this.props.t('nav.logout')}</span></MDBBtn>
                    
                    <hr className='user-navbar-separator'/>
                    <div className='user-navbar-items'>
                        <li>
                            <a 
                                className={activeTabId==1? "user-navbar-items-selected":""} 
                                onClick={() => this.setActiveTabId(1)} 
                                href={UserPagesHashes.DASHBOARD}>
                                    <i className="fas fa-bars"></i>
                                    {this.props.t('nav.user_dashboard')}
                            </a> 
                        </li>
                        <li>
                            <a 
                                className={activeTabId==2? "user-navbar-items-selected":""} 
                                onClick={() => this.setActiveTabId(2)} 
                                href={UserPagesHashes.MY_PROFILE}>
                                    <i className="fas fa-user"></i>
                                    {this.props.t('nav.user_profile')}
                            </a>
                        </li>
                        <li>
                            <a 
                                className={activeTabId==3? "user-navbar-items-selected":""} 
                                onClick={() => this.setActiveTabId(3)} 
                                href={UserPagesHashes.UPLOADS}>
                                    <i className="fas fa-file"></i> 
                                    {this.props.t('nav.user_uploads')}
                            </a>
                        </li>
                        <hr className="user-navbar-separator"/>
                        <li>
                            <a 
                                className={activeTabId==4? "user-navbar-items-selected":""} 
                                onClick={() => this.setActiveTabId(4)} 
                                href={UserPagesHashes.CREATE_USERS}>
                                    <i className="fas fa-database"></i>
                                    {this.props.t('nav.create_users')}
                            </a>
                        </li>
                    </div>
                </div>
    }
}

export default withTranslation('common')(UserNavbar);