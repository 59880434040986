import React from 'react';
import { withTranslation } from 'react-i18next';
import { User } from '../../../_services/users/models/User';
import { UserService } from '../../../_services/users/UserService';
import './UserProfile.css';

const { MDBAnimation } = require('mdbreact')

interface UserProfileProps {
  t: any
}

interface UserProfileState {
  user: User
}

class UserProfile extends React.Component<UserProfileProps, UserProfileState> {
    userService = new UserService()

    constructor(props: UserProfileProps) {
        super(props);

        this.state = {
            user: this.userService.getCurrentUser()
        };
    }

    render() {
      const { t } = this.props;
        return (
          <>
            <meta name="robots" content="follow" />
            <div className='profile-bg'/>
            <MDBAnimation type='fadeIn'>
              <div className="profile-box">
                <MDBAnimation type='fadeIn' duration='500ms'>
                  <div className="profile-information">
                    <p>Email: <span className='profile-values'>{this.state.user.email}</span></p>
                    <p>Name: <span className='profile-values'>{this.state.user.name}</span></p>
                    <p>Company: <span className='profile-values'>{this.state.user.company}</span></p>
                  </div>
                </MDBAnimation>
              </div>
            </MDBAnimation>
        </>
        );
    }
}

export default withTranslation('common')(UserProfile);
