export const allowedTypes = [
    'application/pdf', 
    'image/jpeg', 
    'image/png',  
    'image/jpg',
    'application/vnd.rar', 
    'application/vnd.ms-excel', 
    '', 
    'application/msword',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/x-zip-compressed'
  ]