import React from 'react';
import { withTranslation } from 'react-i18next';
import ingresos from '../../../assets/userpanel/ingresos.png';
import gastos from '../../../assets/userpanel/gastos.jpg';
import otros from '../../../assets/userpanel/other_documents.jpg';
import $ from 'jquery';
import { API_URL }  from '../../../../environment/environment'
import { MDBEdgeHeader, MDBFreeBird, MDBCol, MDBRow, MDBCardBody, MDBCard, 
         MDBCardTitle, MDBCardText, MDBBtn, MDBPopover, MDBPopoverBody, 
         MDBContainer, MDBTabContent, MDBTabPane, MDBNavItem, MDBNavLink, MDBNav, MDBView
} from 'mdbreact';
import './Uploads.css';
import { hasValidFiles } from '../../facilities/checkMimeType';
import { UploadsProps } from './models/UploadsProps';
import { UploadsState } from './models/UploadsState';
import NotificationService from '../../../_services/notifications/NotificationService';
import { NotificationTypes } from '../../../_services/notifications/models/NotificationTypes';
import { UserService } from '../../../_services/users/UserService';

const { MDBAnimation } = require('mdbreact')
const notificationService = new NotificationService();

class Dashboard extends React.Component<UploadsProps, UploadsState> {
  userService = new UserService()  
  overlay: React.RefObject<any>;
    
    constructor(props: UploadsProps) {
        super(props);

        this.overlay = React.createRef()
        
        this.state = {
            currentUser: this.userService.getCurrentUser(),
            selectedFile: null,
            activeItem: "1",
            passwordTooShort: false,
            contactSupport: false,
            submittedFile: false,
            loaded: null
        };
    }

    setOverlay(status: boolean) {
      if (status) {
        this.overlay.current.style.display = "block";
        this.setState({submittedFile: true})
      } else {
        this.overlay.current.style.display = "none";
        this.setState({submittedFile: false})
      }
    }

    toggle = (tab: string) => {
      if (this.state.activeItem !== tab) {
        this.setState({
          activeItem: tab
        });
      }
    };

    onChangeHandler(e: any, t: any) {
      var files: any = e.target.files
  
      if (hasValidFiles(files, e, t)) {
        this.setState({
          selectedFile: files,
          loaded: 0
        })
      }
    }

    uploadFile(tags: { department: string; category: string; }) {
      let uploadData = new FormData();
      if (this.state.selectedFile) {
        this.setOverlay(true)
        for (let x = 0; x < this.state.selectedFile.length; x++) {
          uploadData.append('pdf', this.state.selectedFile[x])
        };

        let info = { 
          email: this.state.currentUser.email, 
          company: this.state.currentUser.company, 
          department: tags.department, 
          category: tags.category
        }

        var settings: any = {
          "url": `${API_URL}/uploads`,
          "headers": {
              'Authorization': `Bearer ${this.state.currentUser.token}`,
              'info': JSON.stringify(info)
          },
          "method": "POST",
          "timeout": 0,
          "processData": false,
          "mimeType": "multipart/form-data",
          "contentType": false,
          "data": uploadData
        };

        $.ajax(settings)
          .done(() => {
            let filesToUpload: Array<any> = [];
            let files: Array<any> = uploadData.getAll('pdf')
            for (let x = 0; x < files.length; x++) {
              filesToUpload.push(files[x].name)
            }
            localStorage.setItem("uploadedFiles", filesToUpload.toString())
            setTimeout(
              () => {
                notificationService.display(NotificationTypes.SUCCESS, 'File succesfully uploaded');
                this.setOverlay(false) 
              },
              2000
            );
          })
        .fail(() => {
          this.setOverlay(false)
          notificationService.display(NotificationTypes.ERROR, 'Could not upload, please contact us if the error persists');
        })
      } else {
        this.setOverlay(false)
        notificationService.display(NotificationTypes.ERROR, 'Please select a file to upload!');
      }
    }

    render() {
      const { t } = this.props;
      var submittedFile = this.state.submittedFile
        return (
          <>
            <div id="overlay" ref={this.overlay}>
              <div id="text">
                <div className="lds-ring d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              </div>
            </div>
            <meta name="robots" content="follow" />
            <div className='userpanel-box'>
              <MDBEdgeHeader className='uploads-background' />
                <MDBAnimation type='fadeIn'>
                <MDBFreeBird>
                    <MDBCol className='mx-auto float-none white z-depth-1 ' >
                      <MDBCardBody className='text-justify'>
                        <div className='uploadBox'>
                          <p>{t('user.textpanel_2')}</p>
                            <MDBAnimation type='fadeIn' duration='500ms'>
                              <MDBNav className="tabs nav-tabs mt-2">
                                <MDBNavItem>
                                  <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={() => this.toggle("1")} role="tab" >
                                  <i className="fas fa-euro-sign"></i> {t('user.tab_accounting')}
                                  </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                  <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={() => this.toggle("2")} role="tab" >
                                  <i className="fas fa-cloud"></i> {t('user.tab_other')}
                                  </MDBNavLink>
                                </MDBNavItem>
                              </MDBNav>
                                <MDBContainer>
                                  <MDBTabContent activeItem={this.state.activeItem}>
                                    <MDBTabPane tabId="1" role="tabpanel">
                                      <MDBRow>
                                        <MDBCol md='12' className='mt-2'>
                                          <MDBRow id='categories'>
                                            <MDBCol md='6'>
                                              <MDBAnimation type='zoomIn' duration='500ms'>
                                                <MDBCard cascade className='my-3 grey lighten-4'>
                                                  <img className="tax-documents" alt="ingresos" height="220" src={ingresos}></img>
                                                  <MDBCardBody cascade className='cardBody text-center'>
                                                    <MDBCardTitle>
                                                      <strong>{t('user.income')}</strong>
                                                    </MDBCardTitle>
                                                    <MDBCardText>
                                                    </MDBCardText>
                                                      <MDBPopover placement='top'  popover clickable id='pop1' className='popover2'>
                                                      <MDBBtn color="light-blue" className="btn-mm">{t('user.textpanel_3')}</MDBBtn>
                                                        <MDBPopoverBody>
                                                          {submittedFile?'':<form method="post" action="#" id="#">
                                                            <div className="form-group files">
                                                              <input name='pdf' onChange={(e) => this.onChangeHandler(e, t)} type="file" className="form-control" multiple/>
                                                              <MDBBtn className="btn btn-red btn-sm" 
                                                                      onClick={(e) => {
                                                                        this.uploadFile({department: 'contabilidad', category: 'ingresos'})
                                                                        }
                                                                      }>Upload</MDBBtn>
                                                            </div>
                                                          </form>}
                                                        </MDBPopoverBody>
                                                      </MDBPopover>
                                                  </MDBCardBody>
                                                </MDBCard>
                                              </MDBAnimation>
                                            </MDBCol>
                                            <MDBCol md='6'>
                                            <MDBAnimation type='fadeInDown' duration='500ms'>
                                                <MDBCard cascade className='my-3 grey lighten-4'>
                                                  <img className="tax-documents" alt="gastos" height="220" src={gastos}></img>
                                                  <MDBCardBody cascade className='text-center'>
                                                    <MDBCardTitle>
                                                      <strong>{t('user.expenses')}</strong>
                                                    </MDBCardTitle>
                                                    <MDBCardText>
                                                    </MDBCardText>  
                                                    <MDBPopover placement='top' popover clickable id='pop1'>
                                                      <MDBBtn color="light-blue" className="btn-mm">{t('user.textpanel_3')}</MDBBtn>
                                                      <MDBPopoverBody>
                                                          <form method="post" action="#" id="#">
                                                            <div className="form-group files">
                                                              <input name='pdf' onChange={(e) => this.onChangeHandler(e, t)} type="file" className="form-control" multiple/>
                                                              <button type="button" 
                                                                      className="btn btn-red btn-sm" 
                                                                      onClick={(e) => this.uploadFile({department: 'contabilidad', category: 'gastos'})}>Upload</button> 
                                                            </div>
                                                          </form>
                                                        </MDBPopoverBody>
                                                      </MDBPopover>
                                                  </MDBCardBody>
                                                </MDBCard>
                                              </MDBAnimation>
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBTabPane>
                                  </MDBTabContent>
                                  <MDBTabContent activeItem={this.state.activeItem} >
                                    <MDBTabPane tabId="2" role="tabpanel">
                                    <MDBRow>
                                        <MDBCol>
                                          <MDBRow id='categories'>
                                            <MDBCol>
                                              <MDBAnimation type='zoomIn' duration='500ms'>
                                                <MDBCard cascade className='other-documents my-3 grey lighten-4'>
                                                    <img className="other-documents" alt="ingresos" height="220" width="280" src={otros}></img>
                                                  <MDBCardBody cascade className='cardBody text-center'>
                                                    <MDBCardTitle>
                                                      <strong>{t('user.documents')}</strong>
                                                    </MDBCardTitle>
                                                    <MDBCardText>
                                                    </MDBCardText>
                                                      <MDBPopover placement='top'  popover clickable id='pop1' className='popover2'>
                                                      <MDBBtn color="light-blue" className="btn-mm">{t('user.textpanel_3')}</MDBBtn>
                                                        <MDBPopoverBody>
                                                          <form method="post" action="#" id="#">
                                                            <div className="form-group files">
                                                              <input name='pdf' onChange={(e) => this.onChangeHandler(e, t)} type="file" className="form-control" multiple/>
                                                              <button type="button" className="btn btn-red btn-sm"
                                                                      onClick={(e) => this.uploadFile({department: 'administración', category: 'documentos'})}>Upload</button>
                                                            </div>
                                                          </form>
                                                        </MDBPopoverBody>
                                                      </MDBPopover>
                                                  </MDBCardBody>
                                                </MDBCard>
                                              </MDBAnimation>
                                            </MDBCol>
                                          </MDBRow>
                                        </MDBCol>
                                      </MDBRow>
                                    </MDBTabPane>
                                  </MDBTabContent>
                              </MDBContainer>
                            </MDBAnimation>
                        </div>
                      </MDBCardBody>
                    </MDBCol>
                </MDBFreeBird>
                </MDBAnimation>
              </div>
        </>
        );
    }
}

export default withTranslation('common')(Dashboard);
