import { NotificationTypes } from '../../_services/notifications/models/NotificationTypes';
import NotificationService from '../../_services/notifications/NotificationService';
import { allowedTypes } from '../constants/allowedTypes'

export function hasValidFiles(files: string | any[], e: { target: { value: null; }; }, t: (arg0: string) => any) {
  let hasValidFiles = true
  const notificationService = new NotificationService()
  const MAXIMUM_FILE_SIZE: number = 10000000
  const MAXIMUM_FILE_LENGTH: number = 10

  for (let x = 0; x<files.length; x++) {
    if (!allowedTypes.includes(files[x].type)) {
      hasValidFiles = false
      notificationService.display(NotificationTypes.ERROR, `${files[x].type} is not a supported format\n`);
    }

    if (files[x].size > MAXIMUM_FILE_SIZE) {
      hasValidFiles = false
      notificationService.display(NotificationTypes.ERROR, 'One of the files size is too big. If this problem persists please contact us');
    }
  };

  if (files.length > MAXIMUM_FILE_LENGTH) {
    hasValidFiles = false
    notificationService.display(NotificationTypes.ERROR, `You can upload a maximum of ${MAXIMUM_FILE_LENGTH} files at a time`);
  }

  return hasValidFiles
}