import React from 'react';
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBCol,
  MDBIcon,
  MDBFreeBird,
  MDBInput,
  MDBBtn
} from 'mdbreact';
import './Kontakt.css';
import { withTranslation } from 'react-i18next';
import { API_URL}  from '../../environment/environment'
import { Dictionary } from 'lodash';

const { MDBAnimation } = require('mdbreact')

interface MyProps {
  t: any
}

interface MyState {
  name: string | null,
  email: string | null;
  topic: string | null;
  subject: string | null;
  submit: boolean;
  errors: Dictionary<boolean>;
}

class Contact extends React.Component<MyProps, MyState> {
    name: any = React.createRef()
    email: any = React.createRef()
    topic: any = React.createRef()
    subject: any = React.createRef()

    constructor(props: MyProps) {
      super(props);

      this.state = {
        name: null,
        email: null,
        topic: null,
        subject: null,
        submit: false,
        errors: {
          server: false,
          form: false,
          email: false
        }
      };

    }

    validateEmail(email: any) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    async sendEmail() {
      const contactData = {
        name: this.name.current.state.innerValue.trim(),
        email: this.email.current.state.innerValue.trim(),
        topic: this.topic.current.state.innerValue.trim(),
        subject: this.subject.current.state.innerValue.trim()
      }

      console.log(contactData)
    
      if (contactData.name && contactData.email && contactData.topic && contactData.subject) {
        if (this.validateEmail(contactData.email)) {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(contactData)
          };
          await fetch(`${API_URL}/contact`, requestOptions)
          .then(response => {
            if (response.status === 200) {
              this.setState({submit: true})
            }
          })
          .catch(error => {
            this.setState({errors: {...this.state.errors, server: true}})
            Promise.reject(error)
          })
        } else {
          this.setState({errors: {...this.state.errors, email: true}})
        }
      } else {
        this.setState({errors: {...this.state.errors, form: true}})
      }
    }

    render() {
      const { t } = this.props;
      let submitted = this.state.submit
      let errors = this.state.errors

      return(
      <div className="mainSectionKontakt">
        <MDBEdgeHeader className='sectionPage_kontakt' />
        <MDBAnimation type='zoomIn' duration='500ms'>
          <MDBFreeBird>
          <MDBContainer className='justify-content-center'>
              <MDBCol md='7' className='mx-auto float-none white z-depth-1 py-2 px-2'>
                    <h1 className="strongHeader">{t('contact.how')}</h1>
                    <h4 className="descriptionTimes"><MDBIcon className="iconTimes" icon="calendar-alt"/> {t('contact.when')}</h4>
                    <h4 className="descriptionTimes"><MDBIcon className="iconTimes" icon="clock"/> 9:30 {t('contact.time')} 14:30h</h4>
                    <h4 className="descriptionTimes"><MDBIcon className="iconTimes" icon="phone"/> +34 96 642 55 43</h4>
                    <h4 className="descriptionTimes"><MDBIcon className="iconTimes" icon="envelope"/> info@eurofiscal.net</h4>
              </MDBCol>
          </MDBContainer>
          </MDBFreeBird>
        </MDBAnimation>
    
        <MDBAnimation type='zoomIn' duration='500ms'>
          <MDBFreeBird className="secondCard">
            <MDBContainer className="mt-5 justify-content-center">
              <h3 className="strongHeader">{t('contact.form')}</h3>
                <MDBCol className='mx-auto float-none white z-depth-1 py-5 px-5' md='6'>
                    {!submitted?<>
                      <form id="contactForm">
                      <div className='grey-text'>
                        <MDBInput
                          ref={this.name} label='Name' id='name'
                          icon='user' group type='text' validate
                          required
                          error='wrong' success='right'
                        />
                        <MDBInput
                          ref={this.email} label='Email' id='email'
                          icon='envelope' group type='email' validate
                          required
                          error='wrong' success='right'
                        />
                        <MDBInput
                          ref={this.topic} group id='topic'
                          required
                          type='textarea' rows='2'
                          label='Betreff' icon='envelope-open'
                        />
                        <MDBInput
                          required
                          ref={this.subject}
                          id='subject' type='textarea'
                          rows='2' label='Anliegen' icon='pencil-alt'
                        />
                      </div>
                      <div className='text-center'>
                        <MDBBtn onClick={() => this.sendEmail()} outline color='green'>
                          {t('contact.send')} <MDBIcon icon='paper-plane' className='ml-1' />
                        </MDBBtn>
                      </div>
                      {errors.form?<div className="formErrors">{t('contact.fill_fields')}</div>:''}
                      {errors.email?<div className="formErrors">{t('contact.wrong_email')}</div>:''}
                      {errors.server?<div className="formErrors">{t('contact.server_down')}</div>:''}
                    </form>
                    </>:
                    <MDBAnimation type="fadeIn" className='text-center'>
                      <h4>
                        <MDBIcon icon='check' color="green" className='ml-1' />
                        {t('contact.contact_soon')}
                      </h4>
                    </MDBAnimation>
                  }
                </MDBCol>
            </MDBContainer>
          </MDBFreeBird>
        </MDBAnimation>
      </div>
    )
  }
}

export default withTranslation('common')(Contact);
