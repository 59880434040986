import React from 'react';
import { withTranslation } from 'react-i18next';
import logo from '../../assets/eurofiscal_logo.png'
import './WebsiteNavbar.css';
import { MDBBtn, MDBCollapse, MDBDropdown, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem, MDBNavLink, MDBPopover, MDBPopoverBody } from 'mdbreact';
import LoginForm from '../login/LoginForm';
import { LanguageSelector } from '../language/selector/LanguageSelector';
import { Redirect } from 'react-router-dom';
import { UserService } from '../../_services/users/UserService';

interface WebsiteNavbarProps {
    t: any
}

interface WebsiteNavbarState {
    collapseID: string
}

class WebsiteNavbar extends React.Component<WebsiteNavbarProps, WebsiteNavbarState> {
    userService = new UserService()

    constructor(props: WebsiteNavbarProps) {
        super(props);

        if (this.userService.getCurrentUser().email) {
            <Redirect to="/#/users/dashboard"/>
        }

        this.state = {
            collapseID: ''
        };
    }

    toggleCollapse = (collapseID: string) => () => {
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }));
      };

    closeCollapse = (collID: string) => () => {
        const { collapseID } = this.state;
        window.scrollTo(0, 0);
        collapseID === collID && this.setState({ collapseID: '' });
      };

    render() {
        const { collapseID } = this.state;
        
        return <MDBNavbar className="navbarMain" color='indigo' dark expand='md' fixed='top' scrolling>
                    <MDBNavbarBrand href='/' className='py-0font-weight-bold'>
                        <img alt="logo" height='40' width="40" src={logo}></img>
                    </MDBNavbarBrand>
                    <MDBNavbarToggler onClick={this.toggleCollapse('mainNavbarCollapse')}/>
                    <MDBCollapse id='mainNavbarCollapse' isOpen={collapseID} navbar>                  
                        <MDBNavbarNav className="align-items-center navItem">
                        <MDBNavItem>
                            <MDBNavLink exact onClick={this.closeCollapse('mainNavbarCollapse')} to='/'>
                                {this.props.t('nav.home')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to='/kanzlei'>
                                {this.props.t('nav.company')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to='/team'>
                                {this.props.t('nav.team')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to='/schwerpunkte'>
                                {this.props.t('nav.activity')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to='/philosophie'>
                                {this.props.t('nav.philosophy')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to='/kontakt'>
                                {this.props.t('nav.contact')}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink onClick={this.closeCollapse('mainNavbarCollapse')} to='/standort'>
                                {this.props.t('nav.location')}
                            </MDBNavLink>
                        </MDBNavItem>

                        <div className="userLoginButton">
                            <MDBPopover className="loginPopover" placement='bottom' popover clickable id='popper3'>
                                <MDBBtn color="default" size="sm">
                                    <i className="far fa-user">
                                        &nbsp;&nbsp;
                                    </i>
                                    <span>{this.props.t('user.login_button')}</span>
                                </MDBBtn>
                                <MDBPopoverBody className="loginPopoverBody">
                                    <LoginForm/>
                                </MDBPopoverBody>
                            </MDBPopover>
                        </div>

                        <LanguageSelector/>

                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBNavbar>
    }
}

export default withTranslation('common')(WebsiteNavbar);