import React from 'react';
import { API_URL }  from '../../../environment/environment'
import { withTranslation } from 'react-i18next';
import './ResponsiveTable.css'
import { UserService } from '../../_services/users/UserService';
const { MDBBtn, MDBModal } = require('mdbreact')

interface MyProps {
  t: Function
}

interface MyState {
  currentUser: any,
  dataModal: boolean,
  password: string,
  contactSupport: boolean,
  passwordTooShort: boolean
}

class DataModal extends React.Component<MyProps, MyState>  {
    userService = new UserService()

    private MAX_PASSWORD_LENGTH = 6
    private MAX_PASSWORD_RETRIES = 3

    constructor(props: MyProps) {
        super(props);
        this.state = {
            currentUser: this.userService.getCurrentUser(),
            dataModal: true,
            password: '',
            contactSupport: false,
            passwordTooShort: false,
        }
    this.setPasswordInput = this.setPasswordInput.bind(this);
    this.changePassword = this.changePassword.bind(this);
    }

    setPasswordInput(event: { target: { value: any; }; }) {
        this.setState({password: event.target.value});
      }

    async changePassword() {
        let tries = 0;
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({email: this.state.currentUser.email, password: this.state.password})
        };
  
        if (this.state.password.length >= this.MAX_PASSWORD_LENGTH && tries < this.MAX_PASSWORD_RETRIES) {
          fetch(`${API_URL}/changePassword`, requestOptions)
            .then(
              () => {
                this.setState({ dataModal: false })
                let newCurrentUser = this.state.currentUser
                newCurrentUser.dataAcceptance = 1
                localStorage.removeItem("currentUser")
                localStorage.setItem("currentUser", newCurrentUser)
                window.location.reload()
                tries++
            }).catch(
              () => this.setState({contactSupport: true})
            )
        } else {
          this.setState({passwordTooShort: true})
        }
      }

    render() {
        const { t } = this.props;
        var passwordTooShort = this.state.passwordTooShort;
        var contactSupport = this.state.contactSupport;
        return (
          <>
            {this.state.currentUser.dataAcceptance === 0 ? 
              <MDBModal isOpen={this.state.dataModal} placement='bottom' className="modalDataAccept">
                <div>
                <b>{t('user.data_acceptance_1')}</b>
                <br/><br/>
                {t('user.data_acceptance_2')}
                <br/><br/>
                {t('user.data_acceptance_3')}
                <br/>
                <a href="https://www.jusline.at/gesetz/dsgvo"> {t('user.data_acceptance_4')} </a>
                <br/><br/>
                {t('user.data_acceptance_5')}
                <br/><br/>
                </div>
                <hr></hr>
                <strong>{t('user.data_acceptance_6')}</strong>
                <div className="passwordInput">
                    <form onSubmit={() => this.changePassword()}>
                        <label>
                          <input 
                            placeholder={t('user.data_acceptance_10')} 
                            type="text" 
                            value={this.state.password} 
                            onChange={this.setPasswordInput}/>
                        </label>
                        <MDBBtn type="submit">{t('user.data_acceptance_7')}</MDBBtn>
                    </form>
                    {passwordTooShort
                      ? <div className="font-color: red;">{t('user.data_acceptance_8')}</div>
                      :''}
                    {contactSupport
                      ? <div className="font-color: red;">{t('user.data_acceptance_9')}</div>
                      :''}
                </div>
              </MDBModal>: ''}
          </>
        )
    }
}

export default withTranslation('common')(DataModal);