import React from 'react';
import { MDBEdgeHeader, MDBFreeBird, MDBCol, MDBRow, MDBCardBody } from 'mdbreact';
import './HomePage.css';

class Impressum extends React.Component {

  render() {
    return (
      <>
        <MDBEdgeHeader className='sectionPage_impressum' />
        <div className='freebird mb-5'>
          <MDBFreeBird>
            <MDBRow>
              <MDBCol md='10' className='mx-auto float-none white z-depth-1 py-2 px-2'>
                <MDBCardBody className='text-justify'>
                      <div className="font-size: 2px;">
                      Verantwortlich für den Inhalt:
                      <br/><br/>
                      (Angaben gemäß § 5 TMG)
                      <br/><br/>
                      Euro Fiscal Levante S.L.
                      <br/><br/>
                      Marqués de Campo, 56 2°
                      E - 03700 Denia / Alicante
                      <br/><br/>
                      Tel.: +34 96 642 55 43
                      <br/><br/>
                      Fax: +34 96 578 67 86
                      <br/><br/>
                      Mail: info@eurofiscal.net
                      <br/><br/>
                      Sitz der Gesellschaft: Denia / Alicante
                      Registro Mercantil de Alicante Tomo 2202 Folio 207 Seccion 8 Hoja A 52.034  
                      <br/><br/>
                      Ust.-Id.-Nr.: ES B 533 456 74
                      <br/><br/>
                      Geschäftsführer:
                      Volkmar Wiczinowsk
                      <br/><br/><br/>
                      1. Inhalte des Onlineangebotes
                      Der Autor übernimmt keinerlei Gewähr für die Vollständigkeit, Aktualität, Korrektheit oder 
                      Qualität der dargebotenen Informationen. Haftungsansprüche gegen den Autor, die sich auf 
                      Schäden ideeller oder materieller Art beziehen, die durch nutzen oder nichtnutzen der dargebotenen 
                      Informationen bzw. durch die Nutzung unvollständiger und fehlerhafter Informationen verursacht 
                      wurden sind ausgeschlossen, sofern seitens des Autors kein nachweislich grob fahrlässiges oder 
                      vorsätzliches Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor 
                      behält es sich ausdrücklich vor, Teile der Seiten oder auch das gesamte Angebot ohne gesonderte 
                      Ankündigung zu ergänzen, zu verändern, zu löschen oder die Veröffentlichung zeitweise oder endgültig 
                      einzustellen.
                      <br/><br/>
                      2. Links und Verweise
                      Bei direkten oder indirekten Links und Verweisen auf fremde Internetseiten, die außerhalb des Verantwortungsbereiches 
                      des Autors liegen, würde eine Haftungsverpflichtung ausschließlich dann in Kraft treten, wenn der Autor von den Inhalten 
                      Kenntnis hätte und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle illegaler Inhalte zu verhindern. 
                      Der Autor erklärt daher ausdrücklich, dass zu dem Zeitpunkt, zu dem der Link gesetzt wurde, die entsprechenden verlinkten 
                      Seiten frei von rechtswidrigen Inhalten waren. Der Autor hat keinerlei Einfluss auf die aktuellen und zukünftigen Inhalte 
                      und auf die Gestaltung der verknüpften/verlinkten Seiten. Deshalb distanziert sich der Autor ausdrücklich von sämtlichen 
                      Inhalten aller verknüpften/verlinkten Seiten, die nach der Verknüpfung/Verlinkung verändert wurden. Dies gilt für alle innerhalb 
                      des eigenen Internetangebotes gesetzten Verweise und Links sowie für Fremdeinträge in vom Autor eingerichteten Diskussionsforen, 
                      Gästebüchern und Mailinglisten. Für rechtswidrige, unvollständige oder fehlerhafte Inhalte und insbesondere für Schäden, 
                      die aus der Nutzung oder Nichtnutzung derart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, 
                      auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Seiten lediglich verweist.
                      <br/><br/>
                      3. Kennzeichen-und Urheberrecht
                      Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Fotos, Texte, Videosequenzen und Tondokumenten zu beachten, von ihm selbst erstellte Grafiken, Fotos, Texte, Videosequenzen und Tondokumente zu nutzen oder auf lizenzfreie Grafiken, Fotos, Texte, Videosequenzen und Tondokumente zurückzugreifen. Alle innerhalb des Internetangebotes genannten und evtl. durch Dritte geschützten Marken und Warenzeichen unterliegen den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweils eingetragenen Eigentümer. Allein durch die bloße Nennung kann nicht geschlossen werden, dass Markenzeichen nicht durch Rechte Dritter geschützt sind. Das Copyright für veröffentlichte und vom Autor selbst erstellte Grafiken, Fotos, Texte, Videosequenzen und Tondokumente bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Fotos, Texte, Videosequenzen und Tondokumente in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.
                      <br/><br/>
                      4. Datenschutz In den Fällen, in denen innerhalb dieses Internetangebotes persönliche oder geschäftliche Daten eingegeben werden können (wie z. B. Namen, Anschriften, E-Mail-Adressen), erfolgt die Preisgabe dieser Daten auf ausdrücklich freiwilliger Basis seitens des jeweiligen Nutzers. Der Autor legt Wert auf den Schutz personenbezogener Daten. Sämtliche Web-Aktivitäten der Euro Fiscal Levante S.L. erfolgen auf der Grundlage der Vorgaben der Europäischen Union und den Empfehlungen des GDBe vom September 2001. Darüber hinaus beachtet die Euro Fiscal Levante S.L. die internationale und spanische Gesetzgebung zum Datenschutz und zur Datensicherheit. Personenbezogene Daten sind Einzelangaben (wie z. B. Namen, Anschriften, E-Mail-Adressen) über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Solche Daten werden nur dann gespeichert, wenn Sie diese der Euro Fiscal Levante S.L.zur Verfügung stehen. Ihre persönlichen Daten werden nicht an Dritte weitergegeben. Sie haben das Recht, Ihre Einwilligung über die Speicherung Ihrer Daten jederzeit mit Wirkung für die Zukunft zu widerrufen. Ausgenommen von Ihrem Widerruf bleiben Daten, deren Löschung gesetzliche, satzungsgemäße oder vertragliche Aufbewahrungspflichten entgegenstehen. Die Erhebung solcher Daten ist explizit gekennzeichnet. Wir weisen Sie gemäß § 33 Bundesdatenschutzgesetz DE darauf hin, dass die Euro Fiscal Levante S.L. die personenbezogenen Daten in maschinenlesbarer Form und im Rahmen der Zweckbestimmung verarbeitet. Alle Daten werden vertraulich behandelt. Die Euro Fiscal Levante S.L. trifft Vorkehrungen, um Ihre personenbezogenen Daten vor Verlust, Zerstörung, Verfälschung, Manipulation und unberechtigten Zugriff zu schützen. 
                      <br/><br/>
                      5. Rechtswirksamkeit dieses Haftungsausschlusses
                      Dieser Haftungsausschluss ist Teil des Internetangebotes, von welchem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Dokumentes der geltenden Rechtsprechung nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrer Gültigkeit und ihrem Inhalt davon unberührt.
                      </div>
                  <MDBRow />
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
        </div>
      </>
    );
  }
}

export default Impressum;
