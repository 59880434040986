import { CompanyCategories } from "../models/CompanyCategories";
import { CompanyDepartments } from "../models/CompanyDepartments";

export function translationHandler(activities: { department: string; category: string; }, lang: (arg0: string) => any) {
    switch (activities.department) {
      case CompanyDepartments.ACCOUNTING:
        activities.department = lang('user.tab_accounting');
        break;
      case CompanyDepartments.ADMINISTRATION:
        activities.department = lang('user.tab_other');
        break; 
    }
  
    switch (activities.category) {
      case CompanyCategories.INCOME:
        activities.category = lang('user.income');
        break;
      case CompanyCategories.EXPENSES:
        activities.category = lang('user.expenses');
        break;
      case CompanyCategories.DOCUMENTS:
        activities.category = lang('user.documents');
        break; 
    }
  }