import toastr from "../../assets/scripts/toastr.js"
import "../../assets/scripts/toastr.css"
import { NotificationTypes } from "./models/NotificationTypes";

class NotificationService {

    display(type: NotificationTypes, message: string) {
        switch (type) {
            case NotificationTypes.SUCCESS:
                toastr.success(message);
                break;
            case NotificationTypes.ERROR:
                toastr.error(message);
                break;
            default:
                console.log("No notification to display")
                break;
        }
    }
}

export default NotificationService;