import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdbreact"
import es from '../../../assets/es.png';
import de from '../../../assets/de.png';
import en from '../../../assets/en.png';
import i18n from "i18next";
import './LanguageSelector.css';

const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem("languageSelected", lang)
}

const getCurrentLanguageFlag = (lang: string) => {
    return <img alt={lang} src={require(`../../../assets/${lang}.png`).default} className='language-flags'/>
}

const getAvailableLanguageFlags = (lang: string) => {
    if (lang === 'de') {
        return <>
            <MDBDropdownItem onClick={() => changeLanguage('es')}><img className="language-flags toggleImg" alt='es' src={es}/></MDBDropdownItem>
            <MDBDropdownItem onClick={() => changeLanguage('en')}><img className="language-flags toggleImg" alt='en' src={en}/></MDBDropdownItem>
        </>
    } else if (lang === 'es') {
        return <>
            <MDBDropdownItem onClick={() => changeLanguage('de')}><img alt='de' src={de} className='language-flags'/></MDBDropdownItem>
            <MDBDropdownItem onClick={() => changeLanguage('en')}><img alt='en' src={en} className='language-flags'/></MDBDropdownItem>
        </>
    } else {
        return <>
            <MDBDropdownItem onClick={() => changeLanguage('es')}><img alt='es' src={es} className='language-flags'/></MDBDropdownItem>
            <MDBDropdownItem onClick={() => changeLanguage('de')}><img alt='de' src={de} className='language-flags'/></MDBDropdownItem>
        </>}
}

export const LanguageSelector = () => {
    var languageSelected = localStorage.getItem("languageSelected") || '';

    return <MDBDropdown className="langDropdown">
                <MDBDropdownToggle className='btn btn-mm text-light text-center' caret color="alpha">
                    {getCurrentLanguageFlag(languageSelected)}
                </MDBDropdownToggle>
                <MDBDropdownMenu className="text-center" basic>
                    {getAvailableLanguageFlags(languageSelected)}
                </MDBDropdownMenu>
            </MDBDropdown>
}