import React from 'react';
import '../assets/css/fonts.css';
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBBtn,
  MDBAnimation
} from 'mdbreact';
import './HomePage.css';
import logo from '../assets/logomain.jpg';
import { withTranslation } from 'react-i18next';
import es from '../assets/es.png';
import de from '../assets/de.png';
import en from '../assets/en.png';
import i18n from "i18next";
import {Helmet} from "react-helmet";

const { MDBModal } = require('mdbreact')

interface MyProps {
  t: any
}

class HomePage extends React.Component<MyProps> {
  scrollToTop = () => window.scrollTo(0, 0);
  state = {
    modal: true
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  translationHandler(lang: string) {
    var language = true;
    localStorage.setItem("language", language.toString());
    localStorage.setItem("languageSelected", lang);
    i18n.changeLanguage(lang)
  }

  render() {
    const { t } = this.props;
    var selectedLang = localStorage.getItem("language");

    return (
      <>
      <meta name="robots" content="follow" />
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={t('meta.description')}/>
          <title>Euro Fiscal</title>
          <link rel="canonical" href="https://www.eurofiscal.net" />
      </Helmet>
      
      {!selectedLang?
        <MDBModal placement='bottom' isOpen={this.state.modal} onClick={this.toggle}>
            <div className="centerButtons">
              <hr/>
                Escoja su idioma deseado
              <MDBBtn className="btn-white" onClick={() => this.translationHandler('es')}><img className="buttonLangImg" alt="es" src={es}/></MDBBtn>
              <hr/>
                Wählen Sie ihre Sprache
              <MDBBtn  className="btn-white" onClick={() => this.translationHandler('de')}><img className="buttonLangImg" alt="de" src={de}/></MDBBtn>
              <hr/>
                Choose your Language
              <MDBBtn  className="btn-white" onClick={() => this.translationHandler('en')}><img className="buttonLangImg" alt="en" src={en}/></MDBBtn>
              <hr/>
            </div>
        </MDBModal>:''}
      <div className="mainSection">
        <MDBEdgeHeader className='sectionPage' />
        <MDBAnimation type='fadeIn'>
            <MDBFreeBird className='mb-1'>
              <MDBRow>
                <MDBCol md='10' className='mx-auto float-none white z-depth-1 py-2 px-2'>
                  <MDBCardBody className='text-justify'>
                  <img src={logo} className="img-fluid" alt="logoimg"/>
                    <MDBRow />
                      <section id="best-features" className="text-center">
                          <h3 className="mb-6 font-weight-bold">{t('homepage.title')}</h3>
                          <div className="col-md-11 mb-5 text-center">
                                  <p className="introText black-text">{t('homepage.p1')}</p>
                          </div>
                          <div className="row">
                              <div className="col-md-4 mb-5">
                                  <i className="fa fa-certificate fa-4x blue-text"></i>
                                  <h4 className="my-4 font-weight-bold">{t('homepage.top1')}</h4>
                                  <p className="black-text">{t('homepage.p2')}</p>
                              </div>
                              <div className="col-md-4 mb-1">
                                  <i className="fa fa-chart-pie fa-4x blue-text"></i>
                                  <h4 className="my-4 font-weight-bold">{t('homepage.top2')}</h4>
                                  <p className="black-text">{t('homepage.p3')}</p>
                              </div>
                              <div className="col-md-4 mb-1">
                                  <i className="fa fa-check-circle fa-4x blue-text"></i>
                                  <h4 className="my-4 font-weight-bold">{t('homepage.top3')}</h4>
                                  <p className="black-text">{t('homepage.p4')}</p>
                              </div>
                          </div>
                      </section>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBFreeBird>
            </MDBAnimation>
          </div>
        </>
    );
  }
}

export default withTranslation('common')(HomePage);
