import React from 'react';
import { MDBCardBody, MDBRow, MDBListGroup, MDBListGroupItem, MDBBadge } from 'mdbreact';
import { withTranslation } from 'react-i18next';

interface MyProps {
    lang: Function,
    totalFiles: number,
    lastUpdate: string;
}

class Charts extends React.Component<MyProps> {
    render() {
        // const dataBar = {
        //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        //     datasets: [
        //     {
        //         label: '#1',
        //         data: [12, 39, 3, 50, 2, 32, 84],
        //         backgroundColor: 'rgba(245, 74, 85, 0.5)',
        //         borderWidth: 1
        //     }, {
        //         label: '#2',
        //         data: [56, 24, 5, 16, 45, 24, 8],
        //         backgroundColor: 'rgba(90, 173, 246, 0.5)',
        //         borderWidth: 1
        //     }, {
        //         label: '#3',
        //         data: [12, 25, 54, 3, 15, 44, 3],
        //         backgroundColor: 'rgba(245, 192, 50, 0.5)',
        //         borderWidth: 1
        //     }
        //     ]
        // };

        // const barChartOptions = {
        //     responsive: true,
        //     maintainAspectRatio: false,
        //     scales: {
        //     xAxes: [{
        //         barPercentage: 1,
        //         gridLines: {
        //         display: true,
        //         color: 'rgba(0, 0, 0, 0.1)'
        //         }
        //     }],
        //     yAxes: [{
        //         gridLines: {
        //         display: true,
        //         color: 'rgba(0, 0, 0, 0.1)'
        //         },
        //         ticks: {
        //         beginAtZero: true
        //         }
        //     }]
        //     }
        // }

        // const dataPie = {
        //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        //     datasets: [
        //     {
        //         data: [300, 50, 100, 40, 120, 24, 52],
        //         backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#ac64ad'],
        //         hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774', '#da92db']
        //     }
        //     ]
        // }
        return (
            <div className="chartSection">
                <MDBRow>
                    <MDBCardBody>
                        <MDBListGroup className="list-group-flush">
                            <MDBListGroupItem>
                            {this.props.lang('user.total_uploads')}
                                <MDBBadge color="primary-color" pill className="float-right">
                                    {this.props.totalFiles}
                                </MDBBadge>
                            </MDBListGroupItem>
                            <MDBListGroupItem>
                                {this.props.lang('user.latest_uploads')}
                                <MDBBadge color="primary-color" pill className="float-right">
                                    {this.props.lastUpdate}
                                </MDBBadge>
                            </MDBListGroupItem>
                        </MDBListGroup>
                    </MDBCardBody>
                </MDBRow>
            </div>
        )
    }
}

export default withTranslation('common')(Charts);