import { Redirect } from "react-router-dom";
import { API_URL } from "../../../environment/environment";
import { handleResponse } from "../../_helpers/handle-response";
import { User } from "./models/User";

export class UserService {
    async login(email: string, password: string) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    email: email, 
                    password: password
                }
            )
        };
    
        return fetch(`${API_URL}/auth`, requestOptions)
            .then(handleResponse) 
            .then(async user => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                <Redirect exact to="/#/users/dashboard"/>
                window.location.reload()
            })
    }
    
    logout(): void {
        localStorage.removeItem('currentUser');
        <Redirect exact to="/#/"/>
        window.location.reload()
    }

    getCurrentUser(): User {
        return JSON.parse(localStorage.getItem("currentUser") || '{}')
    }
}