import React from 'react';
import { withTranslation } from 'react-i18next';
import { MDBEdgeHeader, MDBFreeBird, MDBCol, MDBBtn,
         MDBContainer, MDBTabContent, MDBTabPane, MDBNavItem, MDBNavLink, MDBNav, MDBView, MDBIcon, MDBInput
} from 'mdbreact';
import './Admin.css';
import { UserState } from './models/AdminState';
import { UserProps } from './models/AdminProps'
import { API_URL}  from '../../../../environment/environment'
import { validateEmail } from '../../facilities/validateEmail';
import Activities from '../../components/UserActivities';
import { UserService } from '../../../_services/users/UserService';

const { MDBAnimation } = require('mdbreact')

class Userpanel extends React.Component<UserProps, UserState> {
    userService = new UserService()

    name: any = React.createRef()
    email: any = React.createRef()
    password: any = React.createRef()
    company: any = React.createRef()
    overlay: any;
  
    constructor(props: UserProps) {
        super(props);

        this.overlay = React.createRef()

        this.state = {
            currentUser: this.userService.getCurrentUser(),
            activeItem: "1",
            submit: false,
            errors: {
              server: false,
              form: false,
              email: false
            }
        };
    }

    toggle = (tab: string) => {
      if (this.state.activeItem !== tab) {
        this.setState({
          activeItem: tab
        });
      }
    };

    async createUser() {
      const contactData = {
        creator: this.state.currentUser.email,
        email: this.email.current.state.innerValue.trim(),
        name: this.name.current.state.innerValue.trim(),
        company: this.company.current.state.innerValue.trim(),
        password: this.password.current.state.innerValue.trim()
      }

      if (contactData.name && contactData.email && contactData.company && contactData.password) {
        if (validateEmail(contactData.email)) {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(contactData)
          };
          await fetch(`${API_URL}/users/create`, requestOptions)
            .then(response => {
              if (response.status === 200) {
                this.setState({submit: true})
              } else if (response.status === 400) {
                this.setState({errors: {...this.state.errors, email: true}})
              } else {
                this.setState({errors: {...this.state.errors, server: true}})
              }
            })
            .catch(error => {
              console.log(error)
              this.setState({errors: {...this.state.errors, server: true}})
              Promise.reject(error)
            })
        } else {
          this.setState({errors: {...this.state.errors, email: true}})
        }
      } else {
        this.setState({errors: {...this.state.errors, form: true}})
      }
    }

    render() {
      const { t } = this.props;
      let submitted = this.state.submit
      let errors = this.state.errors
      
        return (
          <>
            <div id="overlay" ref={this.overlay}>
              <div id="text">
                <div className="lds-ring d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              </div>
            </div>
            <meta name="robots" content="follow" />
            <div className='create-users-box'>
              <MDBEdgeHeader id="section_page" className='sectionPage_userpanel' />
                <MDBAnimation type='fadeIn'>
                <MDBFreeBird>
                    <MDBCol className='mainCol float-none white z-depth-1' >
                      <MDBTabContent activeItem={this.state.activeItem} >
                          <div className='uploadBox'>
                          <p>{t('user.admintext_2')}</p>
                          <MDBNav className="tabs nav-tabs mt-3">
                            <MDBNavItem>
                              <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={() => this.toggle("1")} role="tab" >
                              <i className="fas fa-home"></i> Create User
                              </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                              <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={() => this.toggle("2")} role="tab" >
                              <i className="fa fa-info-circle"></i> {t('user.other_users_activities')}
                              </MDBNavLink>
                            </MDBNavItem>
                          </MDBNav>
                          </div>
                          <MDBTabPane tabId="1" role="tabpanel">
                            <MDBAnimation type='fadeIn' duration='500ms'>
                                <MDBContainer>
                                  <MDBAnimation type='zoomIn' duration='500ms'>
                                      <MDBContainer className="mt-5 justify-content-center">
                                          <MDBCol className='mx-auto float-none white z-depth-1 py-5 px-5' md='8'>
                                              {!submitted?<>
                                                <form id="contactForm">
                                                <div className='grey-text'>
                                                  <MDBInput
                                                    ref={this.email} label='Email' id='email'
                                                    group type='email' validate
                                                    required
                                                    error='wrong' success='right'
                                                  />
                                                  <MDBInput
                                                    ref={this.name} label='Name' id='name'
                                                    group type='text' validate
                                                    required
                                                    error='wrong' success='right'
                                                  />
                                                  <MDBInput
                                                    ref={this.company} group id='company'
                                                    required
                                                    type='textarea' rows='2'
                                                    label='Company'
                                                  />
                                                  <MDBInput
                                                    required
                                                    ref={this.password}
                                                    id='subject' type='textarea'
                                                    rows='2' label='Password'
                                                  />
                                                </div>
                                                <div className='text-center'>
                                                  <MDBBtn onClick={() => this.createUser()} outline color='green'>
                                                    Create new user <MDBIcon icon='paper-plane' className='ml-1' />
                                                  </MDBBtn>
                                                </div>
                                                {errors.form?<div className="formErrors">{t('contact.fill_fields')}</div>:''}
                                                {errors.email?<div className="formErrors">This user already exists!</div>:''}
                                                {errors.server?<div className="formErrors">{t('contact.server_down')}</div>:''}
                                              </form>
                                              </>:
                                              <MDBAnimation type="fadeIn" className='text-center'>
                                                <h4>
                                                  <MDBIcon icon='check' color="green" className='ml-1'/>
                                                  {t('user.user_created')}
                                                </h4>
                                              </MDBAnimation>
                                            }
                                          </MDBCol>
                                      </MDBContainer>
                                  </MDBAnimation>
                                </MDBContainer>
                            </MDBAnimation>
                        </MDBTabPane>
                      </MDBTabContent>
                      <MDBTabContent className="text-center" activeItem={this.state.activeItem} >
                        <MDBTabPane tabId="2" role="tabpanel">
                          <div className="view overlay zoom">
                            <MDBView zoom>
                              <Activities lang={t} admin={true}/>
                            </MDBView>
                          </div>
                        </MDBTabPane>
                      </MDBTabContent>
                    </MDBCol>
                </MDBFreeBird>
                </MDBAnimation>
              </div>
        </>
        );
    }
}

export default withTranslation('common')(Userpanel);
