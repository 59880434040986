import React from 'react';
import './assets/css/fonts.css';
import './App.css';
import Routes from './Routes';
import { withTranslation } from 'react-i18next';
import CookieConsent from "react-cookie-consent";
import { HashRouter } from 'react-router-dom';
import WebsiteNavbar from './components/navbar/WebsiteNavbar';
import UserNavbar from './components/navbar/UserNavbar';
import { WebFooter } from './components/footer/WebFooter';
import MetaTags from './conf/MetaTags';
import { UserService } from './_services/users/UserService';
import { User } from './_services/users/models/User';

interface AppProps {
  t: any
}

interface AppState {
  currentUser: User
}

class App extends React.Component<AppProps, AppState> {
  userService = new UserService()

  constructor(props: AppProps){
    super(props);

    this.state = {
        currentUser: this.userService.getCurrentUser()
    };
  }

  render() {
    const { t } = this.props;
    var isDesktop: boolean = window.screen.width >= 1280

    return (
      <HashRouter>
          <MetaTags lang={t}/>

          <CookieConsent 
            declineButtonText={t('cookies.declined')} 
            buttonText={t('cookies.accepted')} 
            enableDeclineButton 
            flipButtons>
              {t('cookies.accept')}
          </CookieConsent>

          { !this.state.currentUser.email
            ? <>
                <WebsiteNavbar/>
                <WebFooter/>
              </> 
            : <UserNavbar/> 
          }
          
          <main>
            <Routes/>
          </main>
          
      </HashRouter>
    );
  };
}

export default withTranslation('common')(App);
